import { 
    takeLatest, 
    call, 
    all, 
    put }                       from 'redux-saga/effects';

import { 
    signInSuccess, 
    signOutUserSuccess, 
    resetPasswordSuccess,
     userError }                from './user.actions';

import { 
    auth,
    functions,
    handleUserProfile, 
    getCurrentUser, 
    googleProvider, 
    facebookProvider, 
    twitterProvider,
    staticGoogleProvider,
    staticFacebookProvider,
    staticTwitterProvider,
    staticEmailProvider }     from "../../firebase/utils";

import { 
    handleResetPasswordAPI,
    handleLinkProvider, 
    handleLinkEmailPassword}    from './user.helpers';

import userTypes                from "./user.types";

/** 
 * Generator Function to return User Data from Database
 * @param {firebase.default.auth.UserCredential}    user                - The user credential from authentication.
 * @param {Object}                                  [additionalData]    - Any additional data provided for the user.
 * 
*/

export function* getSnapshotFromUserAuth(user, additionalData={}) {
    try {
        const userRef   = yield call(handleUserProfile, { userAuth: user, additionalData });
        const snap      = yield userRef.get();

        yield put(
            signInSuccess({
                id  : snap.id,
                  ...snap.data()
              })
        );

    } catch(error) {
        console.log('Error in getSnapshotFromUserAuth():', error)
    }
}

/**
 * Generator Function to sign in with email and password.
 * @param {Object}  payload             - The user credential from authentication.
 * @param {string}  payload.email       - email of the user.
 * @param {string}  payload.password    - password of the user.
 * 
*/

export function* emailSignIn({ payload: { email, password } }) {
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        yield getSnapshotFromUserAuth(user)
        
    } catch (error) {
        console.log("Sign In Error in SignUp/index.js:",error);
    }
}

export function* onEmailSignInStart() {
    yield takeLatest(userTypes.EMAIL_SIGN_IN_START, emailSignIn);
}

// add admin permissions
export function* emailAdminPermissions({ payload: { email } }) {
    console.log(`Called emailAdminPerissions(${email})`)
    const callMakeLuckey = functions.httpsCallable('makeLuckey');
    try {
        const { user } = yield callMakeLuckey({ email: email }).then(result => {
            console.log(`Result: ${result.data}`);
            console.log(result);
        });
        // yield getSnapshotFromUserAuth(user)
        
    } catch (error) {
        console.log("Make Admin Error in emailAdminPermissions/user.sagas.js:",error);
    }
}

export function* emailAdminPermissionsStart() {
    yield takeLatest(userTypes.EMAIL_ADMIN_PERMISSIONS_START, emailAdminPermissions);
}

export function* isUserAuthenticated() {
    try {
        const userAuth = yield getCurrentUser();
        if (!userAuth) return;

        yield getSnapshotFromUserAuth(userAuth);
        
    } catch (error) {
        console.log('Error checking if user is authenticated in isUserAuthenticated:', error);
    }
}

export function* onCheckUserSession() {
    yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOutUser() {
    try {
        yield auth.signOut();
        yield put(
            signOutUserSuccess()
        )
    } catch (error) {
        console.log('Error signing out user:', error);
    }
}

export function* onSignOutUserStart() {
    yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser)
}

export function* signUpUser({ payload: {
    displayName,
    email,
    password,
    confirmPassword
}}) {

    if (password !== confirmPassword) {
        const error = ['Passwords do not match'];
        yield put(
            userError(error)
        );
        return;
    }

    try {
        const { user } = yield auth.createUserWithEmailAndPassword(email, password);
        const additionalData = { displayName };
        yield getSnapshotFromUserAuth(user, additionalData);
        
    } catch(error) {
        console.log('Error Signing up user: ', error);
    }
}

export function* onSignUpUserStart() {
    yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser)
}

export function* resetPassword({ payload: { email }}) {
    try {
        yield call(handleResetPasswordAPI, email);
        yield put(
            resetPasswordSuccess()
        );
    } catch(error) {
        console.log("Error requesting Password Reset:", error);
        yield put(
            userError(error)
        );
    }
}

export function* onResetPasswordStart() {
    yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword)
}

export function* googleSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(googleProvider)
        yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error signing in with Google:', error)
    }
}

export function* onGoogleSignInStart() {
    yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn)
}

export function* facebookSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(facebookProvider)
        yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error signing in with Facebook:', error)
    }
}

export function* onFacebookSignInStart() {
    yield takeLatest(userTypes.FACEBOOK_SIGN_IN_START, facebookSignIn)
}

export function* twitterSignIn() {
    try {
        const { user } = yield auth.signInWithPopup(twitterProvider)
        yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error signing in with Twitter:', error)
    }
}

export function* onTwitterSignInStart() {
    yield takeLatest(userTypes.TWITTER_SIGN_IN_START, twitterSignIn)
}

// Link Providers

export function* linkPassword({ payload: { email, password } }) {
    try {

        const { user } = yield handleLinkEmailPassword(staticEmailProvider, email, password);
        yield getSnapshotFromUserAuth(user)

    } catch (error) {
        console.log("Sign In Error in SignUp/index.js:",error);
    }
}

export function* onLinkPasswordStart() {
    yield takeLatest(userTypes.LINK_PASSWORD_START , linkPassword);
}













export function* linkGoogle() {
    try {
        yield handleLinkProvider(staticGoogleProvider)
        // yield getSnapshotFromUserAuth(user)

    } catch(error) {
        console.log('Error Linking Provider with Google:', error)
    }
}

export function* onLinkGoogleStart() {
    yield takeLatest(userTypes.LINK_GOOGLE_START, linkGoogle)
}


export function* linkFacebook() {
    try {
        yield handleLinkProvider(staticFacebookProvider)

    } catch(error) {
        console.log('Error Linking Provider with Facebook:', error)
    }
}

export function* onLinkFacebookStart() {
    yield takeLatest(userTypes.LINK_FACEBOOK_START, linkFacebook)
}

export function* linkTwitter() {
    try {
        yield handleLinkProvider(staticTwitterProvider)

    } catch(error) {
        console.log('Error Linking Provider with Twitter:', error)
    }
}

export function* onLinkTwitterStart() {
    yield takeLatest(userTypes.LINK_TWITTER_START, linkTwitter)
}

export default function* userSagas() {
    yield all([
        call(onEmailSignInStart),
        call(emailAdminPermissionsStart),
        call(onCheckUserSession),
        call(onSignOutUserStart),
        call(onSignUpUserStart),
        call(onResetPasswordStart),
        call(onGoogleSignInStart),
        call(onFacebookSignInStart),
        call(onTwitterSignInStart),
        call(onLinkPasswordStart),
        call(onLinkGoogleStart),
        call(onLinkFacebookStart),
        call(onLinkTwitterStart)
    ])
}