import React          from 'react';
import PageNotFound   from '../components/PageNotReady';

function PageNotFoundPage() {

  return (
    <>
      <PageNotFound
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our service. We are almost ready to launch this page! Please feel free to leave your info below and we can let you know as soon as we are ready"
      actionText  ="Stay Connected" />
    </>
  );
}

export default PageNotFoundPage;
