import { auth, linkPopup, linkAuth, linkCred } from "../../firebase/utils";

export const handleResetPasswordAPI = (email) => {
    const config = {
        url: 'https://www.luckeyliving.com/login'
    }

    return new Promise((resolve, reject) => {
        auth.sendRecoveryEmail(email,config)
        .then(() => {
            resolve();
        })
        .catch(() => {
            console.log('Error during password reset');
            const err = ['Email not found. Please try again.'];
            reject(err);
        });

    })
}

export const handleLinkEmailPassword = async (staticProvider, email, password) => {
    return new Promise ((resolve, reject) => {
        const credential = staticProvider.credential(email, password);
        linkCred(auth.currentUser, credential)
        .then((usercred) => {
            const user = usercred.user;
            console.log("Account linking success", user);
            resolve(user);
        }).catch((error) => {
            
            console.log("Account linking error", error);
            reject(error);
        });
    })
}

export const handleLinkProvider = async (staticProvider) => {
    return new Promise((resolve, reject) => {
        const provider = new staticProvider();
        provider.setCustomParameters  ({ prompt: 'select_account' });
        linkPopup( linkAuth.currentUser, new staticProvider()).then((result) => {
            // Accounts successfully linked.
            const credential = staticProvider.credentialFromResult(result);
            const user = result.user;
            console.log("Result of handleLinkProvider inside user.helpers.js; Credential:", credential, "User:", user);
            resolve(user);
            // ...
          }).catch((error) => {
    
            console.log("Caught Error while attempting to link account provider: ",error);
            // const err = ['Error linking provider. Please try again.'];

            reject(error)

          });
    })
}