//  TournamentPlacement.js
//
//  Created by Miguel Brown on 3/27/2024, 1:17:53 PM.
//  Copyright © 2024 Luckey Logic. All rights reserved.

import React from "react";

/**
* Class description: The TournamentPlacement model tracks the historical performance of a pickleball player in various tournaments. Each record includes the name of the tournament, the date, the placement or rank achieved by the player, and possibly additional details such as the division or level of play. This data helps to highlight a player's competitive success and can be used for ranking, seeding in future events, or tracking improvement over time.
*/
class TournamentResult extends React.Component {
    
    /**
*@param {string} id             - The unique identifier for the TournamentPlacement object
*@param {string} tournamentName - The name of the pickleball tournament.
*@param {string} tournamentDate - The date or range of dates when the tournament took place.
*@param {Number} placement      - The rank or position the player achieved in the tournament (1st, 2nd, 3rd, etc.).
*@param {string} division       - The specific division or category in which the player competed, such as "Singles", "Doubles", "Mixed Doubles", or skill/age categories.
*@param {string} level          - The skill level of the tournament bracket, often a numeric rating indicating the competitive tier (e.g., 3.0, 4.0, 5.0).
    */
    
    constructor(id, tournamentName, tournamentDate, placement, division, level) {

        /**
	*@property { string } id                - The unique identifier for the TournamentPlacement object
	*@property { string } tournamentName    - The name of the pickleball tournament.
	*@property { string } tournamentDate    - The date or range of dates when the tournament took place.
	*@property { Number } placement         - The rank or position the player achieved in the tournament (1st, 2nd, 3rd, etc.).
	*@property { string } division          - The specific division or category in which the player competed, such as "Singles", "Doubles", "Mixed Doubles", or skill/age categories.
	*@property { string } level             - The skill level of the tournament bracket, often a numeric rating indicating the competitive tier (e.g., 3.0, 4.0, 5.0).
        */

	super(id, tournamentName, tournamentDate, placement, division, level);
    
	this.id             = id; 
    this.tournamentName = tournamentName; 
	this.tournamentDate = tournamentDate; 
	this.placement      = placement; 
	this.division       = division; 
	this.level          = level; 

    }
    /**
     * Function ToDict Converts the TournamentPlacement instance to a dictionary format suitable for database storage or transfer.
     * @function ToDict
     * @returns {Object} A dictionary representation of the TournamentPlacement instance.
     */

    ToDict() {
        return {
			"id"                : this.id,
            "tournamentName"    : this.tournamentName,
			"tournamentDate"    : this.tournamentDate,
			"placement"         : this.placement,
			"division"          : this.division,
			"level"             : this.level
        };
    }

    /**
    * Creates a TournamentPlacement instance from a dictionary format, typically retrieved from a database.
    * @funtion FromDict
    * @property {Object} data The data from database
    * @static
    * @returns {TournamentResult} Returns a TournamentPlacement instance created from the provided data.
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        return new TournamentResult(
			data.id,
            data.tournamentName,
			data.tournamentDate,
			data.placement,
			data.division,
			data.level);
    }

}

/**
* A TournamentPlacement
* @typedef {FirebaseFirestore.FirestoreDataConverter} TournamentPlacementConverter
* @property {Function} toFirestore - Function to send data to database
* @property {Function} fromFirestore - Function to retrieve data from database
*/
        
/**
* @type TournamentPlacementConverter
*/

export const tournamentPlacementConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send TournamentPlacement object to database
     * @param {TournamentResult} tournamentPlacement TournamentPlacement to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function(tournamentPlacement) {
        return {
            id              : tournamentPlacement.id,
            tournamentName  : tournamentPlacement.tournamentName,
			tournamentDate  : tournamentPlacement.tournamentDate,
			placement       : tournamentPlacement.placement,
			division        : tournamentPlacement.division,
			level           : tournamentPlacement.level,
        }
    },
    
    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into TournamentPlacement object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {TournamentResult}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }
        return new TournamentResult (
			data.id,
            data.tournamentName,
			data.tournamentDate,
			data.placement,
			data.division,
			data.level);
    }
}
export default TournamentResult