import React            from 'react';
import { useLocation }  from 'react-router-dom';
import { useSelector }  from 'react-redux';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarRoute,
  SideBtnWrap
} from './sidebar.elements';

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const Sidebar = ({ isOpen, toggle }) => {

  const {currentUser}             = useSelector(mapStateToProps);
  const location                  = useLocation();

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
      <CloseIcon />
      </Icon>
      <SidebarWrapper>
        {currentUser && (
          <>
            <SideBtnWrap>
              <SidebarRoute to='/dashboard'>Dashboard</SidebarRoute>
            </SideBtnWrap>
          </>
        )}

        {!currentUser && (
          <>
            <SideBtnWrap>
              {/* <SidebarRoute to='/signin'>Sign In</SidebarRoute> */}
              <SidebarRoute to={location.pathname === '/signin' ? '/' : '/signin'}>
                {location.pathname === '/signin' ? 'Home' : 'Sign In'}
              </SidebarRoute>
            </SideBtnWrap>
          </>
        )}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
