// paddleSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firestore } from '../../firebase/utils';
import { paddleConverter } from '../../model/Paddle'; // Adjust this path if necessary

// Async thunk for fetching Paddle data
export const fetchPaddle = createAsyncThunk('paddle/fetchPaddle', async (paddleId) => {
    const docRef = firestore.collection('paddles').doc(paddleId).withConverter(paddleConverter);
    const snapshot = await docRef.get();
    if (snapshot.exists) {
        return snapshot.data(); // This will automatically convert to a Paddle instance
    } else {
        throw new Error('Paddle not found');
    }
});

// Slice for Paddle
export const paddleSlice = createSlice({
    name: 'paddle',
    initialState: {
        paddle: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaddle.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaddle.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.paddle = action.payload;
            })
            .addCase(fetchPaddle.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default paddleSlice.reducer;
