// Import necessary dependencies
import React from 'react';
import styled from 'styled-components';

// Create a styled component
const ComingSoonWrapper = styled.div`
    text-align          : center;
    padding             : 50px;
    background-color    : #f9f9f9;
    border-bottom       : 2px solid #e0e0e0;
`;

const Heading = styled.h1`
    font-size           : 36px;
    margin-bottom       : 20px;
    color               : #333;
`;

const SubHeading = styled.h2`
    font-size           : 24px;
    margin-bottom       : 20px;
    color               : #777;
`;

// ComingSoon component definition
const ComingSoon = () => {
    return (
        <ComingSoonWrapper>
            <Heading>Get Ready for Paddle Portal</Heading>
            <SubHeading>Your ultimate Pickleball companion is landing soon.</SubHeading>
        </ComingSoonWrapper>
    );
};

export default ComingSoon;
