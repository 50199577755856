//  Profile.js
//
//  Created by Miguel Brown on 5/1/2024, 2:21:25 PM.
//  Copyright © 2024 Luckey Logic. All rights reserved.

import React from "react";
import PickleballPlayerStats, { pickleballPlayerStatsConverter } from "./PickleballPlayerStats";

/**
* Class description: The public facing Profile model for a Paddle Portal User.
*/
class Profile extends React.Component {
    
    /**
*@param {string}                [id]        - The unique identifier of the Profile object. (Optional)
*@param {string}                name        - The name of the Profile owner.
*@param {string}                username    - The username of the Profile owner.
*@param {string}                email       - The email of the Profile owner.
*@param {Array<Court>}          courts      - The Profile owners list of favorite Courts
*@param {string}                [bio]       - The Profile owners bio text. (Optional)
*@param {string}                [imageUrl]  - The image URL string address for the profile image. (Optional)
*@param {string}                [cImageUrl] - The image URL string address for the Profile cover image. (Optional)
*@param {Array<string>}         paddleIds   - A collection of paddle unique identifiers used to load the Profile owners list of paddles owned.
*@param {PickleballPlayerStats} statistics  - The Profile owners PickleballPlayerStats.
    */
    
    constructor(id, name, username, email, courts, bio, imageUrl, cImageUrl, paddleIds, statistics) {

        /**
	*@property { string }                   id          - The unique identifier of the Profile object.
	*@property { string }                   name        - The name of the Profile owner.
	*@property { string }                   username    - The username of the Profile owner.
	*@property { string }                   email       - The email of the Profile owner.
	*@property { Custom }                   courts      - The Profile owners list of favorite Courts
	*@property { string }                   bio         - The Profile owners bio text.
	*@property { string }                   imageUrl    - The image URL string address for the profile image.
	*@property { string }                   cImageUrl   - The image URL string address for the Profile cover image.
	*@property { string }                   paddleIds   - A collection of paddle unique identifiers used to load the Profile owners list of paddles owned.
	*@property { PickleballPlayerStats }    statistics  - The Profile owners PickleballPlayerStats.
        */

	super(id, name, username, email, courts, bio, imageUrl, cImageUrl, paddleIds, statistics);
    
    this.id         = id; 
    this.name       = name; 
    this.username   = username; 
    this.email      = email; 
    this.courts     = courts; 
    this.bio        = bio; 
    this.imageUrl   = imageUrl; 
    this.cImageUrl  = cImageUrl; 
    this.paddleIds  = paddleIds; 
    this.statistics = statistics; 

    }
    /**
     * Function ToDict Converts the Profile instance to a dictionary format suitable for database storage or transfer.
     * @function    ToDict
     * @returns     {Object} A dictionary representation of the Profile instance.
     */

    ToDict() {
        return {
            "id"            : this.id,
			"name"          : this.name,
			"username"      : this.username,
			"email"         : this.email,
			"courts"        : this.courts,
			"bio"           : this.bio,
			"imageUrl"      : this.imageUrl,
			"cImageUrl"     : this.cImageUrl,
			"paddleIds"     : this.paddleIds,
			"statistics"    : this.statistics
        };
    }

    /**
    * Creates a Profile instance from a dictionary format, typically retrieved from a database.
    * @funtion              FromDict
    * @property {Object}    data        The data from database
    * @static
    * @returns  {Profile}   Returns     a Profile instance created from the provided data.
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        return new Profile(
            data.id,
			data.name,
			data.username,
			data.email,
			data.courts,
			data.bio,
			data.imageUrl,
			data.cImageUrl,
			data.paddleIds,
			data.statistics);
    }

}

/**
* A Profile
* @typedef  {FirebaseFirestore.FirestoreDataConverter} ProfileConverter
* @property {Function} toFirestore      - Function to send data to database
* @property {Function} fromFirestore    - Function to retrieve data from database
*/
        
/**
* @type ProfileConverter
*/

export const profileConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send Profile object to database
     * @param       {Profile}   profile     Profile to be converted.
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function(profile) {
        return {
            id          : profile.id,
			name        : profile.name,
			username    : profile.username,
			email       : profile.email,
			courts      : profile.courts,
			bio         : profile.bio,
			imageUrl    : profile.imageUrl,
			cImageUrl   : profile.cImageUrl,
			paddleIds   : profile.paddleIds,
			statistics  : profile.statistics
        }
    },
    
    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore   Function to convert data from database into Profile object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot        Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options         Snapshot options
     * @returns     {Profile}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }

        // Directly access the statistics map and convert it using pickleballPlayerStatsConverter.
    const statistics = pickleballPlayerStatsConverter.fromFirestore({
        data: () => data.statistics // Passing a function that returns the statistics map.
    }, options);

    statistics.playerId = data.id

        return new Profile (
            data.id,
			data.name,
			data.username,
			data.email,
			data.courts,
			data.bio,
			data.imageUrl,
			data.cImageUrl,
			data.paddleIds,
			statistics);
    }
}
export default Profile