import React, { useState, useEffect }   from 'react';
import { connect }                      from 'react-redux';
import { IconContext }                  from 'react-icons/lib';
import { animateScroll as scroll }      from 'react-scroll';
import LuckeyLogicLogo                  from '../../assets/images/Paddle-Portal-header-logo.gif';

import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavLogo,
  NavLogoImage,
  NavBtn,
  NavBtnLink,
  NavGradient,
  Hamburger
} from './elements';

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const NavbarLimited = ({ toggle }) => {
  const [scrollnav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => {
      window.removeEventListener('scroll', changeNav);
    };
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollnav={ scrollnav ? 1 : 0 }>
          <NavGradient scrollnav={ scrollnav ? 1 : 0 }>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to='/'>
              <NavLogoImage src={LuckeyLogicLogo} />
            </NavLogo>
            <MobileIcon onClick={toggle} scrollnav={ scrollnav ? 1 : 0 }>
              <Hamburger scrollnav={ scrollnav ? 1 : 0 } color='#1993356' />
            </MobileIcon>
            <NavBtn>
              <NavBtnLink to='/'>Home Page</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
          </NavGradient>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default connect(mapStateToProps, null)(NavbarLimited);
