//  Portal.js
//
//  Created by Miguel Brown on 5/1/2024, 10:56:04 AM.
//  Copyright © 2024 Luckey Logic. All rights reserved.

import React from "react";

/**
* Class description: An NFC Tag linked to a document in the Paddle Portal Cloud Database.
*/
class Portal extends React.Component {
    
    /**
*@param {string} [id] - the unique identifier of the Portal Document in Firestore. (Optional)
*@param {string} sn - The Serial Number of the Portal NFC tag used to authenticate a physical NFC tag.
*@param {Custom} dateInit - The date that the Portal was initialized.
*@param {Array<Custom>} lastModified - An array of LastModified instances for data accountability.
*@param {Custom} status - The status of the Portal
*@param {Custom} allocation - The allocation of the Portal to give context for its uses and purpose.
*@param {string} [comment] - Any comment to provide more context for the Portal. (Optional)
*@param {string} [allocatedUID] - The unique identifier of the object that the Portal is allocated to. (Optional)
*@param {Custom} [location] - The location of the Portal if applicable. (Optional)
    */
    
    constructor(id, sn, dateInit, lastModified, status, allocation, comment, allocatedUID, location) {

        /**
	*@property { string } id - the unique identifier of the Portal Document in Firestore.
	*@property { string } sn - The Serial Number of the Portal NFC tag used to authenticate a physical NFC tag.
	*@property { Custom } dateInit - The date that the Portal was initialized.
	*@property { Custom } lastModified - An array of LastModified instances for data accountability.
	*@property { Custom } status - The status of the Portal
	*@property { Custom } allocation - The allocation of the Portal to give context for its uses and purpose.
	*@property { string } comment - Any comment to provide more context for the Portal.
	*@property { string } allocatedUID - The unique identifier of the object that the Portal is allocated to.
	*@property { Custom } location - The location of the Portal if applicable.
        */

	super(id, sn, dateInit, lastModified, status, allocation, comment, allocatedUID, location);

	this.id = id; 
		this.sn = sn; 
		this.dateInit = dateInit; 
		this.lastModified = lastModified; 
		this.status = status; 
		this.allocation = allocation; 
		this.comment = comment; 
		this.allocatedUID = allocatedUID; 
		this.location = location; 

    }
    /**
     * Function ToDict Converts the Portal instance to a dictionary format suitable for database storage or transfer.
     * @function ToDict
     * @returns {Object} A dictionary representation of the Portal instance.
     */

    ToDict() {
        return {
	"id" : this.id,
			"sn" : this.sn,
			"dateInit" : this.dateInit,
			"lastModified" : this.lastModified,
			"status" : this.status,
			"allocation" : this.allocation,
			"comment" : this.comment,
			"allocatedUID" : this.allocatedUID,
			"location" : this.location
        };
    }

    /**
    * Creates a Portal instance from a dictionary format, typically retrieved from a database.
    * @funtion FromDict
    * @property {Object} data The data from database
    * @static
    * @returns {Portal} Returns a Portal instance created from the provided data.
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        return new Portal(
	data.id,
			data.sn,
			data.dateInit,
			data.lastModified,
			data.status,
			data.allocation,
			data.comment,
			data.allocatedUID,
			data.location);
    }

}

/**
* A Portal
* @typedef {FirebaseFirestore.FirestoreDataConverter} PortalConverter
* @property {Function} toFirestore - Function to send data to database
* @property {Function} fromFirestore - Function to retrieve data from database
*/
        
/**
* @type PortalConverter
*/

export const portalConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send Portal object to database
     * @param {Portal} portal Portal to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function(portal) {
        return {                
	id : portal.id,
			sn : portal.sn,
			dateInit : portal.dateInit,
			lastModified : portal.lastModified,
			status : portal.status,
			allocation : portal.allocation,
			comment : portal.comment,
			allocatedUID : portal.allocatedUID,
			location : portal.location
        }
    },
    
    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into Portal object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {Portal}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }

        const id = data.id || snapshot.id;
        return new Portal (
            id,
			data.sn,
			data.dateInit,
			data.lastModified,
			data.status,
			data.allocation,
			data.comment,
			data.allocatedUID,
			data.location);
    }
}
export default Portal