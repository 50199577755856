import { combineReducers } from 'redux';

import userReducer      from './User/user.reducer';
import portalReducer    from './Portal/portalSlice';
import paddleReducer    from './Paddle/paddleSlice';
import profileReducer   from './Profile/profileSlice';

export default combineReducers({
    user    : userReducer,
    portal  : portalReducer,
    paddle  : paddleReducer,
    profile : profileReducer
});