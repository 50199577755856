import userTypes from "./user.types";

const INIT_STATE = {
    currentUser             : null,
    resetPasswordSuccess    : false,
    userError               : []
};

const userReducer = (state=INIT_STATE, action) => {
    switch(action.type) {
        case userTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser : action.payload,
                userError   : []
            }
        case userTypes.USER_ERROR:
            return {
                ...state,
                userError   : action.payload
            }
        case userTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordSuccess: action.payload
            }
        case userTypes.RESET_USER_STATE:
        case userTypes.SIGN_OUT_USER_SUCCESS:
            return {
                ...state,
                ...INIT_STATE
            }
        default:
            return state;    
    }
};

export default userReducer;