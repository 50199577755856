// Import necessary dependencies
import React, { useState } from "react";
import styled from 'styled-components';
import { firestore } from "../../firebase/utils";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../../firebase/config";

// Create styled components
const FormWrapper = styled.div`
    text-align          : center;
    padding             : 20px;
    background-color    : #f9f9f9;
    border-bottom       : 2px solid #e0e0e0;
    margin-top          : -2px;  // To overlap with the ComingSoonWrapper border
`;

const EmailInput = styled.input`
    padding         : 10px;
    font-size       : 16px;
    border          : 1px solid #ccc;
    border-radius   : 5px;
    margin-right    : 10px;
    width           : 200px;
`;

const CommentInput = styled.textarea`
    padding         : 10px;
    font-size       : 16px;
    border          : 1px solid #ccc;
    border-radius   : 5px;
    margin-top      : 10px;
    width           : 100%;
    resize          : vertical; /* Allow vertical resizing */
`;

const NotifyButton = styled.button`
    padding             : 10px 20px;
    font-size           : 16px;
    border              : none;
    background-color    : #008CBA;
    color               : white;
    cursor              : pointer;
    border-radius       : 5px;
    transition          : background-color 0.3s;

    &:hover {
        background-color: #005f5f;
    }
`;

const Message = styled.p`
    color       : #333;
    text-align  : center;
    margin-top  : 10px;
`;

// // Define the NotifyMe component
// function NotifyMe() {
//     const [email, setEmail] = useState("");
//     const [message, setMessage] = useState("");

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         firebase.initializeApp(firebaseConfig);
//         try {
//             await firestore.collection("ProspectEmails").add({ email });
//             setMessage("Thank you! We'll notify you when we launch.");
//         } catch (error) {
//             console.error(error);
//             setMessage("Oops, something went wrong. Please try again later.");
//         }

//         setEmail("");
//     };

//     return (
//         <FormWrapper id="notify-me">
//             <form onSubmit={handleSubmit}>
//                 <EmailInput
//                     type="email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     placeholder="Enter your email"
//                     required
//                 />
//                 <NotifyButton type="submit">Notify Me</NotifyButton>
//             </form>
//             {message && <Message>{message}</Message>}
//         </FormWrapper>
//     );
// }

// Define the NotifyMe component
function NotifyMe() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [comment, setComment] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        firebase.initializeApp(firebaseConfig);
        try {
            await firestore.collection("ProspectEmails").add({
                email,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                status: "created",
                comment
            });
            setMessage("Thank you! We'll notify you when we launch.");
        } catch (error) {
            console.error(error);
            setMessage("Oops, something went wrong. Please try again later.");
        }

        setEmail("");
        setComment("");
    };

    return (
        <FormWrapper id="notify-me">
            <form onSubmit={handleSubmit}>
                <EmailInput
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                />
                <CommentInput
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Leave a comment (optional)"
                />
                <NotifyButton type="submit">Notify Me</NotifyButton>
            </form>
            {message && <Message>{message}</Message>}
        </FormWrapper>
    );
}


export default NotifyMe;



// // NotifyMe.js
// import React, { useState } from "react";
// import { firestore } from "../../firebase/utils";
// import firebase from "firebase/compat/app";
// import { firebaseConfig } from "../../firebase/config";
// // import { firestore } from "./firebaseConfig";

// function NotifyMe() {
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     firebase.initializeApp(firebaseConfig)
//     try {
//       await firestore.collection("ProspectEmails").add({ email });
//       setMessage("Thank you! We'll notify you when we launch.");
//     } catch (error) {
//       console.error(error);
//       setMessage("Oops, something went wrong. Please try again later.");
//     }

//     setEmail("");
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Enter your email"
//           required
//         />
//         <button type="submit">Notify Me</button>
//       </form>
//       {message && <p>{message}</p>}
//     </div>
//   );
// }

// export default NotifyMe;
