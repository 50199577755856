import                                    './App.css';
import React, { useEffect }         from  'react'
import { useDispatch }              from  'react-redux'
import { Routes, Route, Navigate }  from  'react-router-dom'
import { checkUserSession }         from  './../redux/User/user.actions';

//HOC

//Pages
import Home                         from './../pages';
import PageNotFoundPage             from './../pages/pagenotready';


//Layouts
import UnauthenticatedLayout        from './../layouts/UnauthenticatedLayout';
import LimitedLayout                from './../layouts/LimitedLayout';
import PortalPage                   from '../pages/portalPage';
import PaddleDetailView from '../components/PaddleDetailView';


const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(checkUserSession());
  }, [dispatch])

  return (
          <Routes>
            <Route exact  path='*'                    element={
                <UnauthenticatedLayout>
                    <Home />
                </UnauthenticatedLayout>
            } />

            <Route path="/portals/:portalId" element={
              <PortalPage/>
            } exact />

            <Route exact path="/paddle/:paddleId" element={
              <PaddleDetailView />
            }

            />

            <Route exact  path="/privacy.html"        element={
                <LimitedLayout label="Home"           destination="/">
                  <Navigate push to="/privacy.html"/>
                </LimitedLayout>
            } />

              <Route exact  path='/dashboard'           element={
                <h1>Dashboard</h1>
            } />
            
            {/* <Route        path='*'                    element={
                <LimitedLayout label="Home"           destination="/" >
                  <PageNotFoundPage />
                </LimitedLayout>
            } /> */}
          </Routes>
          );
}

export default App;