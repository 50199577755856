import firebase             from    'firebase/compat/app';
import                              'firebase/compat/firestore';
import                              'firebase/compat/auth';
import                              'firebase/compat/functions';
import                              'firebase/compat/storage';
import { firebaseConfig }   from    './config';

import {getAuth,
    linkWithPopup,
    linkWithCredential,
    EmailAuthProvider,
    GoogleAuthProvider,
    FacebookAuthProvider,
    TwitterAuthProvider} from "firebase/auth";


firebase.initializeApp(firebaseConfig);

export const auth                   = firebase.auth();
export const functions              = firebase.functions();
export const linkPopup              = linkWithPopup;
export const firestore              = firebase.firestore();
export const storage                = firebase.storage();
// export const getAuth             = getAuth()


export const googleProvider         = new   GoogleAuthProvider();
export const facebookProvider       = new   FacebookAuthProvider();
export const twitterProvider        = new   TwitterAuthProvider();
export const emailProvider          = new   EmailAuthProvider();

export const staticGoogleProvider   =       GoogleAuthProvider;
export const staticFacebookProvider =       FacebookAuthProvider;
export const staticTwitterProvider  =       TwitterAuthProvider;
export const staticEmailProvider    =       EmailAuthProvider;

googleProvider.setCustomParameters  ({ prompt: 'select_account' });

export const signInWithFacebook     = () => auth.signInWithPopup(facebookProvider);
export const signInWithTwitter      = () => auth.signInWithPopup(twitterProvider);
export const linkWithGoogle         = () =>     handleLinkProvider(googleProvider,      GoogleAuthProvider);
export const linkWithFacebook       = () =>     handleLinkProvider(facebookProvider,    FacebookAuthProvider);
export const linkWithTwitter        = () =>     handleLinkProvider(twitterProvider,     TwitterAuthProvider);
export const linkWithPassword       = () =>     handleLinkProvider(emailProvider,       EmailAuthProvider);

export const ProviderOptionSet = { 
    'password'      : 1 << 0,
    'phone'         : 1 << 1,
    'google.com'    : 1 << 2,
    'apple.com'     : 1 << 3,
    'yahoo.com'     : 1 << 4,
    'facebook.com'  : 1 << 5,
    'twitter.com'   : 1 << 6,
    'microsoft.com' : 1 << 7
 }


export const linkAuth = getAuth();
export const linkCred = linkWithCredential;


export const handleLinkProvider = async (provider, staticProvider) => {
    linkWithPopup(linkAuth.currentUser, new staticProvider()).then((result) => {
        // Accounts successfully linked.
        const credential = staticProvider.credentialFromResult(result);
        const user = result.user;
        console.log("Credential:", credential, "User:", user);
        // ...
      }).catch((error) => {

        console.log(error);
        // Handle Errors here.
        // ...
      });
}

/**
    * Renders a thing of a component
    * @param {Object}                               props
    * @param {firebase.default.auth.UserCredential} props.userAuth - the color of the text in the button
    * @param {Object}                               props.additionalData - the background color of the button
*/
 
const handleUserProfile = async ({ userAuth, additionalData }) => {
    console.log(`Called handleUserProfile. !userAuth = ${!userAuth}`);
    if (!userAuth) return;

    console.log(`User Data\n`, userAuth)
    var providerOptionSet = userAuth.providerData.map(({ providerId }) => {
        console.log("In Map:", providerId, ProviderOptionSet[`${providerId}`]);
        return ProviderOptionSet[`${providerId}`]
    }).reduce((a, b) => a | b, 0);

    console.log("Provider Option Set:", providerOptionSet)

    const { uid } = userAuth;

    const userRef = firestore.doc(`Users/${uid}`);
    const snap = await userRef.get();

    if (!snap.exists) {

        console.log(userAuth);
        
        const { uid, displayName, email, providerData, photoURL } = userAuth;
        const timestamp = new Date();
        
        try {
            await userRef.set({
                uid,
                displayName,
                email,
                photoURL,
                providerOptionSet,
                providerData,
                dateCreated: timestamp,
                dateUpdated: timestamp,
                ...additionalData
            })
        } catch(err) {
            console.log("Error Logging In:", err)
        }
    } else if (providerOptionSet !== snap.data().providerOptionSet) {
        const { providerData } = userAuth;
        const timestamp = new Date();

        console.log("New Provider", providerOptionSet, snap.data().providerOptionSet)

        try {
            await userRef.update({
                providerOptionSet,
                providerData,
                dateUpdated: timestamp,
                ...additionalData
            })
        } catch(err) {
            console.log(err)
        }
    } else {
        console.log("Provider Matches", providerOptionSet, snap.data().providerOptionSet)
    }

    return userRef;
}

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
            unsubscribe();
            resolve(userAuth);
        }, reject);
    })
}

export { handleUserProfile }