import React, {
    useState,
    useEffect           } from 'react';
import {
    useDispatch,
    useSelector         } from 'react-redux';
import { useParams      } from 'react-router-dom';
import { fetchPaddle    } from '../../redux/Paddle/paddleSlice';
import { fetchProfile   } from '../../redux/Profile/profileSlice';
import { storage        } from '../../firebase/utils';
import {
    DetailContainer,
    PaddleImage,
    PaddleDetails,
    DetailItem,
    PaddleName,
    PaddleBrand,
    DetailLabel,
    ProfileImage,
    ProfileDetails,
    UserName,
    LeftProfileDetails,
    StatContainer,
    StatBlock,
    StatLabel,
    StatValue } from './index.elements';

const PaddleDetailView = () => {
    const { paddleId }                          = useParams();
    const dispatch                              = useDispatch();
    const paddle                                = useSelector(state => state.paddle.paddle);
    const profile                               = useSelector(state => state.profile.currentProfile);
    const status                                = useSelector(state => state.paddle.status);
    const [profileImageUrl, setProfileImageUrl] = useState('');

    useEffect(() => {
        if (paddleId) {
            dispatch(fetchPaddle(paddleId));
        }
    }, [dispatch, paddleId]);

    useEffect(() => {
        if (paddle && paddle.ownerId && (!profile || profile.id !== paddle.ownerId)) {
            dispatch(fetchProfile(paddle.ownerId));
        }
    }, [dispatch, paddle, profile]);

    useEffect(() => {
        if (profile && profile.imageUrl) {
            const thumbPath = `users/${profile.id}/images/thumbs/${profile.imageUrl.split('/').pop().split('.')[0]}_200x200.jpg`;
            storage.ref(thumbPath).getDownloadURL()
                .then(url => {
                    setProfileImageUrl(url);
                })
                .catch(() => {
                    const fullPath = `users/${profile.id}/images/${profile.imageUrl.split('/').pop()}`;
                    storage.ref(fullPath).getDownloadURL()
                        .then(url => {
                            setProfileImageUrl(url);
                        });
                });
        }
    }, [profile]);

    const formatPrice = price => price ? `$${price.toFixed(2)}` : 'N/A';

    if (status === 'loading') return <div>Loading...</div>;
    if (!paddle) return <div>Paddle not found</div>;

    return (
        <DetailContainer>
            <PaddleDetails>
                <PaddleBrand>{paddle.brand}</PaddleBrand>
                <PaddleName>{paddle.name}</PaddleName>
                {paddle.imageUrl && <PaddleImage src={paddle.imageUrl} alt={`${paddle.brand} ${paddle.name}`} />}
                <DetailItem>{paddle.description}</DetailItem>
                <DetailItem><DetailLabel>Price:</DetailLabel> {formatPrice(paddle.price)}</DetailItem>
                <DetailItem><DetailLabel>Rating:</DetailLabel> {paddle.rating} ({paddle.reviewCount} reviews)</DetailItem>
                <DetailItem><DetailLabel>Weight:</DetailLabel> {paddle.weight} oz</DetailItem>
                <DetailItem><DetailLabel>Dimensions:</DetailLabel> {paddle.length}" x {paddle.width}" x {paddle.thickness}"</DetailItem>
                <DetailItem><DetailLabel>Grip Size:</DetailLabel> {paddle.gripSize}"</DetailItem>
                <DetailItem><DetailLabel>USA Approved:</DetailLabel> {paddle.usaApproved ? 'Yes' : 'No'}</DetailItem>
            </PaddleDetails>
            {profile && (
                <ProfileDetails>
                    <LeftProfileDetails>
                        <UserName>{profile.name}</UserName>
                        <StatContainer>
                            <StatBlock>
                                <StatLabel>Player Rating:</StatLabel>
                                <StatValue>{profile.statistics.playerRating}</StatValue>
                            </StatBlock>
                        </StatContainer>
                        <StatContainer>
                            <StatBlock>
                                <StatLabel>Wins:</StatLabel>
                                <StatValue>{profile.statistics.wins}</StatValue>
                            </StatBlock>
                            <StatBlock>
                                <StatLabel>Losses:</StatLabel>
                                <StatValue>{profile.statistics.losses}</StatValue>
                            </StatBlock>
                        </StatContainer>
                        <StatContainer>
                            <StatBlock>
                                <StatLabel>Win/Loss Ratio:</StatLabel>
                                <StatValue>{profile.statistics.winLossRatio}</StatValue>
                            </StatBlock>
                        </StatContainer>
                        <StatContainer>
                            <StatBlock>
                                <StatLabel>Games Played:</StatLabel>
                                <StatValue>{profile.statistics.gamesPlayed}</StatValue>
                            </StatBlock>
                        </StatContainer>
                    </LeftProfileDetails>
                    <ProfileImage src={profileImageUrl} alt={profile.name} />
                </ProfileDetails>
            )}
        </DetailContainer>
    );
};

export default PaddleDetailView;


// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { fetchPaddle } from '../../redux/Paddle/paddleSlice';
// import { fetchProfile } from '../../redux/Profile/profileSlice';
// import { storage } from '../../firebase/utils'; // Ensure you have this path set correctly to your Firebase config
// import { DetailContainer, PaddleImage, PaddleDetails, DetailItem, PaddleName, PaddleBrand, DetailLabel, ProfileImage, ProfileDetails, UserName, UserInfo } from './index.elements';
// import { LeftProfileDetails } from './index.elements';
// import { StatContainer } from './index.elements';
// import { Stat } from './index.elements';

// const PaddleDetailView = () => {
//     const { paddleId } = useParams();
//     const dispatch = useDispatch();
//     const paddle = useSelector(state => state.paddle.paddle);
//     const profile = useSelector(state => state.profile.currentProfile);
//     const status = useSelector(state => state.paddle.status);

//     useEffect(() => {
//         if (paddleId) {
//           dispatch(fetchPaddle(paddleId));
//         }
//     }, [dispatch, paddleId]);

//     useEffect(() => {
//         if (paddle && paddle.ownerId && (!profile || profile.id !== paddle.ownerId)) {
//           dispatch(fetchProfile(paddle.ownerId));
//         }
//     }, [dispatch, paddle, profile]);

//     useEffect(() => {
//         if (profile && profile.imageUrl) {
//             const thumbPath = `users/${profile.id}/images/thumbs/${profile.imageUrl.split('/').pop().split('.')[0]}_200x200.jpg`;
//             storage.ref(thumbPath).getDownloadURL()
//                 .then(url => {
//                     document.getElementById('profileImg').src = url;
//                 })
//                 .catch(() => {
//                     const fullPath = `users/${profile.id}/images/${profile.imageUrl.split('/').pop()}`;
//                     storage.ref(fullPath).getDownloadURL()
//                         .then(url => {
//                             document.getElementById('profileImg').src = url;
//                         });
//                 });
//         }
//     }, [profile]);

//     const formatPrice = price => price ? `$${price.toFixed(2)}` : 'N/A';

//     if (status === 'loading') return <div>Loading...</div>;
//     if (!paddle) return <div>Paddle not found</div>;

//     return (
//         <DetailContainer>
//             <PaddleDetails>
//                 <PaddleBrand>{paddle.brand}</PaddleBrand>
//                 <PaddleName>{paddle.name}</PaddleName>
//                 {paddle.imageUrl && <PaddleImage src={paddle.imageUrl} alt={`${paddle.brand} ${paddle.name}`} />}
//             </PaddleDetails>
//             {profile && (
//                 <ProfileDetails>
//                     <LeftProfileDetails>
//                         <UserName>{profile.name}</UserName>
//                         <UserInfo>{`Player Rating: ${profile.statistics.playerRating}`}</UserInfo>
//                         <StatContainer>
//                             <Stat>{`Wins: ${profile.statistics.wins}`}</Stat>
//                             <Stat>{`Losses: ${profile.statistics.losses}`}</Stat>
//                         </StatContainer>
//                         <UserInfo>{`Win/Loss Ratio: ${profile.statistics.winLossRatio}`}</UserInfo>
//                     </LeftProfileDetails>
//                     <ProfileImage src={imageUrl} alt={profile.name} />
//                 </ProfileDetails>
//             )}
//         </DetailContainer>
//     );
    

//     // return (
//     //     <DetailContainer>
//     //         {paddle && (
//     //             <PaddleDetails>
//     //                 <PaddleBrand>{paddle.brand}</PaddleBrand>
//     //                 <PaddleName>{paddle.name}</PaddleName>
//     //                 {paddle.imageUrl && <PaddleImage src={paddle.imageUrl} alt={`${paddle.brand} ${paddle.name}`} />}
//     //                 <DetailItem>{paddle.description}</DetailItem>
//     //                 <DetailItem><DetailLabel>Price:</DetailLabel> {formatPrice(paddle.price)}</DetailItem>
//     //                 <DetailItem><DetailLabel>Rating:</DetailLabel> {paddle.rating} ({paddle.reviewCount} reviews)</DetailItem>
//     //                 <DetailItem><DetailLabel>Weight:</DetailLabel> {paddle.weight} oz</DetailItem>
//     //                 <DetailItem><DetailLabel>Dimensions:</DetailLabel> {paddle.length}" x {paddle.width}" x {paddle.thickness}"</DetailItem>
//     //                 <DetailItem><DetailLabel>Grip Size:</DetailLabel> {paddle.gripSize}"</DetailItem>
//     //                 <DetailItem><DetailLabel>USA Approved:</DetailLabel> {paddle.usaApproved ? 'Yes' : 'No'}</DetailItem>
//     //             </PaddleDetails>
//     //         )}
//     //         {profile && (
//     //             <ProfileDetails>
//     //                 <UserName>{profile.name}</UserName>
//     //                 <UserInfo>{`Player Rating: ${profile.statistics.playerRating}`}</UserInfo>
//     //                 <UserInfo>{`Wins: ${profile.statistics.wins} Losses: ${profile.statistics.losses}`}</UserInfo>
//     //                 <UserInfo>{`Win/Loss Ratio: ${profile.statistics.winLossRatio}`}</UserInfo>
//     //                 <ProfileImage id="profileImg" alt={profile.name} />
//     //             </ProfileDetails>
//     //         )}
//     //     </DetailContainer>
//     // );
// };

// export default PaddleDetailView;