import React, { useState }  from 'react';
import InfoSection          from '../components/InfoSection';

import {
  adminHomeObj1,
  adminHomeObj2,
  adminHomeObj3,
  adminHomeObj4,
  adminHomeObj5,
} from '../components/InfoSection/Data';
import ComingSoon from './comingSoon';
import NotifyMe from '../components/NotifyMe';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ComingSoon />
      <NotifyMe />
      <InfoSection {...adminHomeObj1} />
      <InfoSection {...adminHomeObj2} />
      <InfoSection {...adminHomeObj3} />
      <InfoSection {...adminHomeObj4} />
      <InfoSection {...adminHomeObj5} />
    </>
  );
}

export default Home;
