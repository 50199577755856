//  MatchResult.js
//
//  Created by Miguel Brown on 5/2/2024, 10:35:54 AM.
//  Copyright © 2024 Luckey Logic. All rights reserved.

import React from "react";

/**
* Class description: Represents the result of a pickleball match, encapsulating all pertinent details about the match including players, teams, scores, and events that occurred during the match.
*/
class MatchResult extends React.Component {
    
    /**
*@param {string}            [id]            - Unique identifier for the match, nullable for newly created matches. (Optional)
*@param {string}            matchType       - Type of match, either 'Singles' or 'Doubles'.
*@param {Number}            gamePoint       - The game point threshold needed to win the match.
*@param {Boolean}           winByTwo        - Indicates if the win must be by at least two points.
*@param {Array<string>}     playerIds       - Array of player IDs participating in the match.
*@param {Array<string>}     teamA           - Array of player IDs constituting Team A.
*@param {Array<string>}     teamB           - Array of player IDs constituting Team B.
*@param {Date}              startTime       - Start time of the match.
*@param {Date}              endTime         - End time of the match.
*@param {Number}            finalScoreTeamA - Final score for Team A.
*@param {Number}            finalScoreTeamB - Final score for Team B.
*@param {Array<MatchEvent>} events          - List of events (like points scored, faults, etc.) that occurred during the match.
    */
    
    constructor(id, matchType, gamePoint, winByTwo, playerIds, teamA, teamB, startTime, endTime, finalScoreTeamA, finalScoreTeamB, events) {

        /**
	*@property { string } id                - Unique identifier for the match, nullable for newly created matches.
	*@property { string } matchType         - Type of match, either 'Singles' or 'Doubles'.
	*@property { Number } gamePoint         - The game point threshold needed to win the match.
	*@property { Boolean } winByTwo         - Indicates if the win must be by at least two points.
	*@property { string } playerIds         - Array of player IDs participating in the match.
	*@property { string } teamA             - Array of player IDs constituting Team A.
	*@property { string } teamB             - Array of player IDs constituting Team B.
	*@property { Custom } startTime         - Start time of the match.
	*@property { Custom } endTime           - End time of the match.
	*@property { Number } finalScoreTeamA   - Final score for Team A.
	*@property { Number } finalScoreTeamB   - Final score for Team B.
	*@property { Custom } events            - List of events (like points scored, faults, etc.) that occurred during the match.
        */

	super(id, matchType, gamePoint, winByTwo, playerIds, teamA, teamB, startTime, endTime, finalScoreTeamA, finalScoreTeamB, events);
    
    this.id                 = id; 
	this.matchType          = matchType; 
	this.gamePoint          = gamePoint; 
	this.winByTwo           = winByTwo; 
	this.playerIds          = playerIds; 
	this.teamA              = teamA; 
	this.teamB              = teamB; 
	this.startTime          = startTime; 
	this.endTime            = endTime; 
	this.finalScoreTeamA    = finalScoreTeamA; 
	this.finalScoreTeamB    = finalScoreTeamB; 
	this.events             = events; 

    }
    /**
     * Function ToDict Converts the MatchResult instance to a dictionary format suitable for database storage or transfer.
     * @function ToDict
     * @returns {Object} A dictionary representation of the MatchResult instance.
     */

    ToDict() {
        return {
            "id"                : this.id,
			"matchType"         : this.matchType,
			"gamePoint"         : this.gamePoint,
			"winByTwo"          : this.winByTwo,
			"playerIds"         : this.playerIds,
			"teamA"             : this.teamA,
			"teamB"             : this.teamB,
			"startTime"         : this.startTime,
			"endTime"           : this.endTime,
			"finalScoreTeamA"   : this.finalScoreTeamA,
			"finalScoreTeamB"   : this.finalScoreTeamB,
			"events"            : this.events
        };
    }

    /**
    * Creates a MatchResult instance from a dictionary format, typically retrieved from a database.
    * @funtion FromDict
    * @property {Object} data The data from database
    * @static
    * @returns {MatchResult} Returns a MatchResult instance created from the provided data.
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        return new MatchResult(
            data.id,
			data.matchType,
			data.gamePoint,
			data.winByTwo,
			data.playerIds,
			data.teamA,
			data.teamB,
			data.startTime,
			data.endTime,
			data.finalScoreTeamA,
			data.finalScoreTeamB,
			data.events);
    }

}

/**
* A MatchResult
* @typedef {FirebaseFirestore.FirestoreDataConverter} MatchResultConverter
* @property {Function} toFirestore - Function to send data to database
* @property {Function} fromFirestore - Function to retrieve data from database
*/
        
/**
* @type MatchResultConverter
*/

export const matchResultConverter = {

    /**
     * Function toFirestore
     * @property {Function} toFirestore Function to send MatchResult object to database
     * @param {MatchResult} matchResult MatchResult to be converted
     * @returns {FirebaseFirestore.DocumentData}
     */

    toFirestore: function(matchResult) {
        return {
            id              : matchResult.id,
			matchType       : matchResult.matchType,
			gamePoint       : matchResult.gamePoint,
			winByTwo        : matchResult.winByTwo,
			playerIds       : matchResult.playerIds,
			teamA           : matchResult.teamA,
			teamB           : matchResult.teamB,
			startTime       : matchResult.startTime,
			endTime         : matchResult.endTime,
			finalScoreTeamA : matchResult.finalScoreTeamA,
			finalScoreTeamB : matchResult.finalScoreTeamB,
			events          : matchResult.events
        }
    },
    
    /**
     * Function fromFirestore
     * @property {Function} fromFirestore Function to convert data from database into MatchResult object
     * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
     * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
     * @returns {MatchResult}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }
        return new MatchResult (data.id,
			data.matchType,
			data.gamePoint,
			data.winByTwo,
			data.playerIds,
			data.teamA,
			data.teamB,
			data.startTime,
			data.endTime,
			data.finalScoreTeamA,
			data.finalScoreTeamB,
			data.events);
    }
}
export default MatchResult