import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortal } from './../redux/Portal/portalSlice';

const PortalPage = () => {
    const { portalId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const portal = useSelector((state) => state.portal.portal);
    const status = useSelector((state) => state.portal.status);
    const error = useSelector((state) => state.portal.error);

    useEffect(() => {
        dispatch(fetchPortal(portalId));
    }, [dispatch, portalId]);

    // Redirect based on allocation type and allocatedId
    useEffect(() => {
        // if (portal && portal.status === 'Active' && portal.allocation === 'Paddle' && portal.allocatedUID) {
        //     navigate(`/paddle/${portal.allocatedUID}`);
        // }
        if (portal && portal.status === 'Active' && portal.allocatedUID) {
            const path = getRedirectPath(portal);
            navigate(path);
        }
    }, [portal, navigate]);

    if (status === 'loading') return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!portal) return <div>Portal not found</div>;

    const getRedirectPath = (portal) => {
        switch (portal.allocation) {
            case 'Paddle':
                return `/paddle/${portal.allocatedUID}`;
            case 'Court':
                return `/court/${portal.allocatedUID}`;
            case 'Facility':
                return `/facility/${portal.allocatedUID}`;
            case 'Staging':
                return `/staging/${portal.allocatedUID}`;
            case 'Available':
            default:
                return '/available'; // Or some default route
        }
    };

    // Optional: Render some information or a loading state until the redirect kicks in
    return (
        <div>Redirecting to the allocated resource...</div>
    );
};

export default PortalPage;



// import React, { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchPortal } from './../redux/Portal/portalSlice';
// import Portal from '../model/Portal';

// /**
//  * Page component for displaying a Portal's details.
//  * @returns {JSX.Element}
//  */
// const PortalPage = () => {
//     const { portalId } = useParams();
//     const dispatch = useDispatch();

//     /** @type {{portal: Portal, status: string, error: string}} */
//     const { portal, status, error } = useSelector((state) => state.portal);

//     useEffect(() => {
//         dispatch(fetchPortal(portalId));
//     }, [dispatch, portalId]);

//     if (status === 'loading') return <div>Loading...</div>;
//     if (error) return <div>Error: {error}</div>;
//     if (!portal) return <div>Portal not found</div>;

//     return (
//         <div>
//             <h1>ID: {portal.id} SN: {portal.sn}</h1> {/* Assuming 'sn' is a property of the Portal model */}
//             <p>Status: {portal.status}</p> {/* Example usage */}
//             <p>Allocation: {portal.allocation}</p>
//         </div>
//     );
// };

// export default PortalPage;