//  PickleballPlayerStats.js
//
//  Created by Miguel Brown on 3/27/2024, 1:02:28 PM.
//  Copyright © 2024 Luckey Logic. All rights reserved.

import React from "react";
import TournamentResult from "./TournamentPlacement";
import MatchResult 		from "./MatchResult";

/**
* Class description: A data model to capture and represent various statistics for a pickleball player. This model includes the player's skill rating, performance metrics, and tournament achievements. It provides a comprehensive overview of a player's competitive history and current form.
*/
class PickleballPlayerStats extends React.Component {
    
    /**
*@param {string} 						id 						- The unique identifier for this player statistic object.
*@param {Number} 						playerId 				- The unique identifier for the player profile object.
*@param {Number} 						playerRating 			- A numeric representation of a player's skill level, typically ranging from 1.0 (beginner) to 5.5 or higher (advanced/professional), based on standardized assessments or competitive results.
*@param {Array<TournamentResult>} 		tournamentResults 		- A collection of the player's placements in tournaments, detailing the tournament name, the placement position, and the date.
*@param {Number} 						highestAchievedRating 	- The highest player rating the individual has achieved in their playing history.
*@param {Number} 						[currentRanking] 		- The player's current ranking position within a defined league or association, if applicable. (Optional)
*@param {string} 						[favoriteShot] 			- The player's self-reported favorite or most effective shot or play style. (Optional)
*@param {Array<MatchResult} 			matchResults 			- A collection of the player's match results in normal gameplay.
    */
    
    constructor(id, playerId, playerRating, tournamentResults, highestAchievedRating, currentRanking, favoriteShot, matchResults) {

        /**
	*@property {string} 						id 						- The unique identifier for this player statistic object.
	*@property {Number} 						playerId 				- The unique identifier for the player profile object.
	*@property {Number} 						playerRating 			- A numeric representation of a player's skill level, typically ranging from 1.0 (beginner) to 5.5 or higher (advanced/professional), based on standardized assessments or competitive results.
	*@property {Array<TournamentResult>} 		tournamentResults 		- A collection of the player's placements in tournaments, detailing the tournament name, the placement position, and the date.
	*@property {Number} 						highestAchievedRating 	- The highest player rating the individual has achieved in their playing history.
	*@property {Number} 						[currentRanking] 		- The player's current ranking position within a defined league or association, if applicable. (Optional)
	*@property {string} 						[favoriteShot] 			- The player's self-reported favorite or most effective shot or play style. (Optional)
	*@property {Array<MatchResult} 				matchResults 			- A collection of the player's match results in normal gameplay.
        */

	super(id, playerId, playerRating, tournamentResults, highestAchievedRating, currentRanking, favoriteShot, matchResults);

	this.id 					= id; 
	this.playerId 				= playerId; 
	this.playerRating 			= playerRating; 
	this.tournamentResults 		= tournamentResults; 
	this.highestAchievedRating 	= highestAchievedRating; 
	this.currentRanking 		= currentRanking; 
	this.favoriteShot 			= favoriteShot;
	this.matchResults			= matchResults;

    }

	/**
 * Returns the total number of games played by accumulating the length of the matchResults array.
 * @returns {number} Total number of games played.
 */
get gamesPlayed() {
    return this.matchResults.length;
}

/**
 * Computes the total number of wins by examining each match result.
 * The player wins if they were on the team that scored more than the other team.
 * @returns {number} Total wins.
 */
get wins() {
    return this.matchResults.filter(match => {
        const playerOnTeamA = match.teamA.includes(this.playerId); // Check if the player is in teamA
        const playerOnTeamB = match.teamB.includes(this.playerId); // Check if the player is in teamB

        if (playerOnTeamA && match.finalScoreTeamA > match.finalScoreTeamB) {
            return true; // Player was on teamA and teamA scored more
        } else if (playerOnTeamB && match.finalScoreTeamB > match.finalScoreTeamA) {
            return true; // Player was on teamB and teamB scored more
        }
        return false; // No win condition met
    }).length;
}

/**
 * Computes the total number of losses by examining each match result.
 * The player loses if they were on the team that scored less than the other team.
 * @returns {number} Total losses.
 */
get losses() {
    return this.matchResults.filter(match => {
        const playerOnTeamA = match.teamA.includes(this.playerId); // Check if the player is in teamA
        const playerOnTeamB = match.teamB.includes(this.playerId); // Check if the player is in teamB

        if (playerOnTeamA && match.finalScoreTeamA < match.finalScoreTeamB) {
            return true; // Player was on teamA and teamA scored less
        } else if (playerOnTeamB && match.finalScoreTeamB < match.finalScoreTeamA) {
            return true; // Player was on teamB and teamB scored less
        }
        return false; // No loss condition met
    }).length;
}

/**
 * Calculates the win-loss ratio. If no losses, returns 0 to avoid division by zero.
 * @returns {number} Win to loss ratio.
 */
get winLossRatio() {
    return this.losses > 0 ? this.wins / this.losses : 0;
}

/**
 * Retrieves the total number of tournaments played by getting the length of the tournamentResults array.
 * @returns {number} Total tournaments played.
 */
get tournamentsPlayed() {
    return this.tournamentResults.length;
}

/**
 * Identifies the most recent tournament from the tournamentResults array based on the date.
 * @returns {Object|null} The most recent tournament object or null if no tournaments.
 */
get mostRecentTournament() {
    return this.tournamentResults.reduce((latest, tournament) =>
        latest && latest.date > tournament.date ? latest : tournament, null);
}

/**
 * Computes the average points scored per game by summing up points scored and dividing by the number of games.
 * @returns {number} Average points scored per game.
 */
get avgPointsScored() {
    const totalPoints = this.matchResults.reduce((sum, match) => sum + match.pointsScored, 0);
    return this.gamesPlayed > 0 ? totalPoints / this.gamesPlayed : 0;
}

/**
 * Computes the average points scored against the player per game by summing up points against and dividing by the number of games.
 * @returns {number} Average points against per game.
 */
get avgPointsAgainst() {
    const totalPoints = this.matchResults.reduce((sum, match) => sum + match.pointsAgainst, 0);
    return this.gamesPlayed > 0 ? totalPoints / this.gamesPlayed : 0;
}

/**
 * Computes the total number of service aces by summing up aces from all matches.
 * @returns {number} Total number of aces.
 */
get aceCount() {
    return this.matchResults.reduce((sum, match) => sum + match.aces, 0);
}

/**
 * Computes the total number of faults committed during service by summing up faults from all matches.
 * @returns {number} Total number of faults.
 */
get faultCount() {
    return this.matchResults.reduce((sum, match) => sum + match.faults, 0);
}

/**
 * Computes the number of games won without the opponent scoring (pickle) by filtering matches where opponent's score is zero.
 * @returns {number} Number of pickles given.
 */
get picklesGiven() {
    return this.matchResults.filter(match => match.opponentScore === 0).length;
}

/**
 * Computes the number of games lost without scoring any points (received pickle) by filtering matches where player's score is zero.
 * @returns {number} Number of pickles received.
 */
get picklesReceived() {
    return this.matchResults.filter(match => match.playerScore === 0).length;
}

    /**
     * Function ToDict Converts the PickleballPlayerStats instance to a dictionary format suitable for database storage or transfer.
     * @function ToDict
     * @returns {Object} A dictionary representation of the PickleballPlayerStats instance.
     */

    ToDict() {
        return {
			"id" 					: this.id,
			"playerId" 				: this.playerId,
			"playerRating" 			: this.playerRating,
			"gamesPlayed" 			: this.gamesPlayed,
			"wins" 					: this.wins,
			"losses" 				: this.losses,
			"winLossRatio" 			: this.winLossRatio,
			"tournamentsPlayed" 	: this.tournamentsPlayed,
			"tournamentResults" 	: this.tournamentResults,
			"mostRecentTournament" 	: this.mostRecentTournament,
			"avgPointsScored" 		: this.avgPointsScored,
			"avgPointsAgainst" 		: this.avgPointsAgainst,
			"highestAchievedRating" : this.highestAchievedRating,
			"currentRanking" 		: this.currentRanking,
			"favoriteShot" 			: this.favoriteShot,
			"aceCount" 				: this.aceCount,
			"faultCount" 			: this.faultCount,
			"mostPlayedAgainst" 	: this.mostPlayedAgainst,
			"picklesGiven" 			: this.picklesGiven,
			"picklesReceived" 		: this.picklesReceived
        };
    }

    /**
    * Creates a PickleballPlayerStats instance from a dictionary format, typically retrieved from a database.
    * @funtion 							FromDict
    * @property {Object} 				data 		The data from database
    * @static
    * @returns {PickleballPlayerStats} 				Returns a PickleballPlayerStats instance created from the provided data.
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        return new PickleballPlayerStats(
			data.id,
			data.playerId,
			data.playerRating,
			data.tournamentResults,
			data.highestAchievedRating,
			data.currentRanking,
			data.favoriteShot,
			data.matchResults
		)
	}

}

/**
* A PickleballPlayerStats
* @typedef 	{FirebaseFirestore.FirestoreDataConverter} PickleballPlayerStatsConverter
* @property {Function} toFirestore 		- Function to send data to database
* @property {Function} fromFirestore 	- Function to retrieve data from database
*/
        
/**
* @type PickleballPlayerStatsConverter
*/

export const pickleballPlayerStatsConverter = {

    /**
     * Function 							toFirestore
     * @property 	{Function} 				toFirestore 			Function to send PickleballPlayerStats object to database
     * @param 		{PickleballPlayerStats} pickleballPlayerStats 	PickleballPlayerStats to be converted
     * @returns 	{FirebaseFirestore.DocumentData}
     */

    toFirestore: function(pickleballPlayerStats) {
        return {
			id 						: pickleballPlayerStats.id,
			playerId 				: pickleballPlayerStats.playerId,
			playerRating 			: pickleballPlayerStats.playerRating,
			tournamentResults 		: pickleballPlayerStats.tournamentResults,
			highestAchievedRating 	: pickleballPlayerStats.highestAchievedRating,
			currentRanking 			: pickleballPlayerStats.currentRanking,
			favoriteShot 			: pickleballPlayerStats.favoriteShot,
			matchResults			: pickleballPlayerStats.matchResults
        }
    },
    
    /**
     * Function fromFirestore
     * @property 	{Function} 									fromFirestore 	Function to convert data from database into PickleballPlayerStats object
     * @param 		{FirebaseFirestore.QueryDocumentSnapshot} 	snapshot 		Data returned from database
     * @param 		{FirebaseFirestore.SnapshotOptions} 		options 		Snapshot options
     * @returns 	{PickleballPlayerStats}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }
        return new PickleballPlayerStats (
			data.id,
			data.playerId,
			data.playerRating,
			data.tournamentResults,
			data.highestAchievedRating,
			data.currentRanking,
			data.favoriteShot,
			[]
		)
    }
}
export default PickleballPlayerStats


// //  PickleballPlayerStats.js
// //
// //  Created by Miguel Brown on 3/27/2024, 1:02:28 PM.
// //  Copyright © 2024 Luckey Logic. All rights reserved.

// import React from "react";
// import TournamentPlacement from "./TournamentPlacement";

// /**
// * Class description: A data model to capture and represent various statistics for a pickleball player. This model includes the player's skill rating, performance metrics, and tournament achievements. It provides a comprehensive overview of a player's competitive history and current form.
// */
// class PickleballPlayerStats extends React.Component {
    
//     /**
// *@param {string} 						id 						- The unique identifier for this player statistic object
// *@param {Number} 						playerRating 			- A numeric representation of a player's skill level, typically ranging from 1.0 (beginner) to 5.5 or higher (advanced/professional), based on standardized assessments or competitive results.
// *@param {Number} 						gamesPlayed 			- The total number of recorded games that the player has participated in, serving as a measure of experience.
// *@param {Number} 						wins 					- The total number of games that the player has won.
// *@param {Number} 						losses 					- The total number of games that the player has lost.
// *@param {Number} 						winLossRatio 			- A calculated metric representing the player's win to loss ratio, indicating overall performance efficiency.
// *@param {Number} 						tournamentsPlayed 		- The total number of tournaments in which the player has competed.
// *@param {Array<TournamentPlacement>} 	tournamentPlacements 	- A collection of the player's placements in tournaments, detailing the tournament name, the placement position, and the date.
// *@param {TournamentPlacement} 			mostRecentTournament 	- Information about the most recent tournament where the player competed, including name, placement, and date.
// *@param {Number} 						avgPointsScored 		- The average number of points scored by the player per game.
// *@param {Number} 						avgPointsAgainst 		- The average number of points scored against the player per game.
// *@param {Number} 						highestAchievedRating 	- The highest player rating the individual has achieved in their playing history.
// *@param {Number} 						[currentRanking] 		- The player's current ranking position within a defined league or association, if applicable. (Optional)
// *@param {string} 						[favoriteShot] 			- The player's self-reported favorite or most effective shot or play style. (Optional)
// *@param {Number} 						aceCount 				- The number of service aces the player has achieved in official matches.
// *@param {Number} 						faultCount 				- The number of faults committed by the player during service in official matches.
// *@param {string} 						[mostPlayedAgainst] 	- The name of the opponent the player has faced the most in competitive play. (Optional)
// *@param {Number} 						picklesGiven 			- The number of times the player has won a game without the opponent scoring any points. This indicates a dominant performance by the player.
// *@param {Number} 						picklesReceived 		- A count of the player's games lost without scoring any points, reflecting matches where the player was overpowered by the opponent.
//     */
    
//     constructor(id, playerRating, gamesPlayed, wins, losses, winLossRatio, tournamentsPlayed, tournamentPlacements, mostRecentTournament, avgPointsScored, avgPointsAgainst, highestAchievedRating, currentRanking, favoriteShot, aceCount, faultCount, mostPlayedAgainst, picklesGiven, picklesReceived) {

//         /**
// 	*@property { string } 						id 						- The unique identifier for this player statistic object
// 	*@property { Number } 						playerRating 			- A numeric representation of a player's skill level, typically ranging from 1.0 (beginner) to 5.5 or higher (advanced/professional), based on standardized assessments or competitive results.
// 	*@property { Number } 						gamesPlayed 			- The total number of recorded games that the player has participated in, serving as a measure of experience.
// 	*@property { Number } 						wins 					- The total number of games that the player has won.
// 	*@property { Number } 						losses 					- The total number of games that the player has lost.
// 	*@property { Number } 						winLossRatio 			- A calculated metric representing the player's win to loss ratio, indicating overall performance efficiency.
// 	*@property { Number } 						tournamentsPlayed 		- The total number of tournaments in which the player has competed.
// 	*@property { Array<TournamentPlacement> } 	tournamentPlacements 	- A collection of the player's placements in tournaments, detailing the tournament name, the placement position, and the date.
// 	*@property { TournamentPlacement } 			mostRecentTournament	- Information about the most recent tournament where the player competed, including name, placement, and date.
// 	*@property { Number } 						avgPointsScored 		- The average number of points scored by the player per game.
// 	*@property { Number } 						avgPointsAgainst 		- The average number of points scored against the player per game.
// 	*@property { Number } 						highestAchievedRating 	- The highest player rating the individual has achieved in their playing history.
// 	*@property { Number } 						currentRanking 			- The player's current ranking position within a defined league or association, if applicable.
// 	*@property { string } 						favoriteShot 			- The player's self-reported favorite or most effective shot or play style.
// 	*@property { Number } 						aceCount 				- The number of service aces the player has achieved in official matches.
// 	*@property { Number } 						faultCount 				- The number of faults committed by the player during service in official matches.
// 	*@property { string } 						mostPlayedAgainst 		- The name of the opponent the player has faced the most in competitive play.
// 	*@property { Number } 						picklesGiven 			- The number of times the player has won a game without the opponent scoring any points. This indicates a dominant performance by the player.
// 	*@property { Number } 						picklesReceived 		- A count of the player's games lost without scoring any points, reflecting matches where the player was overpowered by the opponent.
//         */

// 	super(id, playerRating, gamesPlayed, wins, losses, winLossRatio, tournamentsPlayed, tournamentPlacements, mostRecentTournament, avgPointsScored, avgPointsAgainst, highestAchievedRating, currentRanking, favoriteShot, aceCount, faultCount, mostPlayedAgainst, picklesGiven, picklesReceived);

// 	this.id 					= id; 
// 	this.playerRating 			= playerRating; 
// 	this.gamesPlayed 			= gamesPlayed; 
// 	this.wins 					= wins; 
// 	this.losses 				= losses; 
// 	this.winLossRatio 			= winLossRatio; 
// 	this.tournamentsPlayed 		= tournamentsPlayed; 
// 	this.tournamentPlacements 	= tournamentPlacements; 
// 	this.mostRecentTournament 	= mostRecentTournament; 
// 	this.avgPointsScored 		= avgPointsScored; 
// 	this.avgPointsAgainst		= avgPointsAgainst; 
// 	this.highestAchievedRating 	= highestAchievedRating; 
// 	this.currentRanking 		= currentRanking; 
// 	this.favoriteShot 			= favoriteShot; 
// 	this.aceCount 				= aceCount; 
// 	this.faultCount 			= faultCount; 
// 	this.mostPlayedAgainst 		= mostPlayedAgainst; 
// 	this.picklesGiven 			= picklesGiven; 
// 	this.picklesReceived 		= picklesReceived; 

//     }
//     /**
//      * Function ToDict Converts the PickleballPlayerStats instance to a dictionary format suitable for database storage or transfer.
//      * @function ToDict
//      * @returns {Object} A dictionary representation of the PickleballPlayerStats instance.
//      */

//     ToDict() {
//         return {
// 			"id" 					: this.id,
// 			"playerRating" 			: this.playerRating,
// 			"gamesPlayed" 			: this.gamesPlayed,
// 			"wins" 					: this.wins,
// 			"losses" 				: this.losses,
// 			"winLossRatio" 			: this.winLossRatio,
// 			"tournamentsPlayed" 	: this.tournamentsPlayed,
// 			"tournamentPlacements" 	: this.tournamentPlacements,
// 			"mostRecentTournament" 	: this.mostRecentTournament,
// 			"avgPointsScored" 		: this.avgPointsScored,
// 			"avgPointsAgainst" 		: this.avgPointsAgainst,
// 			"highestAchievedRating" : this.highestAchievedRating,
// 			"currentRanking" 		: this.currentRanking,
// 			"favoriteShot" 			: this.favoriteShot,
// 			"aceCount" 				: this.aceCount,
// 			"faultCount" 			: this.faultCount,
// 			"mostPlayedAgainst" 	: this.mostPlayedAgainst,
// 			"picklesGiven" 			: this.picklesGiven,
// 			"picklesReceived" 		: this.picklesReceived
//         };
//     }

//     /**
//     * Creates a PickleballPlayerStats instance from a dictionary format, typically retrieved from a database.
//     * @funtion FromDict
//     * @property {Object} data The data from database
//     * @static
//     * @returns {PickleballPlayerStats} Returns a PickleballPlayerStats instance created from the provided data.
//     */

//     static FromDict(data) {
//         if (data == null) {
//             return data
//         }
//         return new PickleballPlayerStats(
// 			data.id,
// 			data.playerRating,
// 			data.gamesPlayed,
// 			data.wins,
// 			data.losses,
// 			data.winLossRatio,
// 			data.tournamentsPlayed,
// 			data.tournamentPlacements,
// 			data.mostRecentTournament,
// 			data.avgPointsScored,
// 			data.avgPointsAgainst,
// 			data.highestAchievedRating,
// 			data.currentRanking,
// 			data.favoriteShot,
// 			data.aceCount,
// 			data.faultCount,
// 			data.mostPlayedAgainst,
// 			data.picklesGiven,
// 			data.picklesReceived);
//     }

// }

// /**
// * A PickleballPlayerStats
// * @typedef {FirebaseFirestore.FirestoreDataConverter} PickleballPlayerStatsConverter
// * @property {Function} toFirestore - Function to send data to database
// * @property {Function} fromFirestore - Function to retrieve data from database
// */
        
// /**
// * @type PickleballPlayerStatsConverter
// */

// export const pickleballPlayerStatsConverter = {

//     /**
//      * Function toFirestore
//      * @property {Function} toFirestore Function to send PickleballPlayerStats object to database
//      * @param {PickleballPlayerStats} pickleballPlayerStats PickleballPlayerStats to be converted
//      * @returns {FirebaseFirestore.DocumentData}
//      */

//     toFirestore: function(pickleballPlayerStats) {
//         return {
// 			id 						: pickleballPlayerStats.id,
// 			playerRating 			: pickleballPlayerStats.playerRating,
// 			gamesPlayed 			: pickleballPlayerStats.gamesPlayed,
// 			wins 					: pickleballPlayerStats.wins,
// 			losses	 				: pickleballPlayerStats.losses,
// 			winLossRatio 			: pickleballPlayerStats.winLossRatio,
// 			tournamentsPlayed 		: pickleballPlayerStats.tournamentsPlayed,
// 			tournamentPlacements 	: pickleballPlayerStats.tournamentPlacements,
// 			mostRecentTournament 	: pickleballPlayerStats.mostRecentTournament,
// 			avgPointsScored 		: pickleballPlayerStats.avgPointsScored,
// 			avgPointsAgainst	 	: pickleballPlayerStats.avgPointsAgainst,
// 			highestAchievedRating 	: pickleballPlayerStats.highestAchievedRating,
// 			currentRanking 			: pickleballPlayerStats.currentRanking,
// 			favoriteShot 			: pickleballPlayerStats.favoriteShot,
// 			aceCount 				: pickleballPlayerStats.aceCount,
// 			faultCount 				: pickleballPlayerStats.faultCount,
// 			mostPlayedAgainst 		: pickleballPlayerStats.mostPlayedAgainst,
// 			picklesGiven 			: pickleballPlayerStats.picklesGiven,
// 			picklesReceived 		: pickleballPlayerStats.picklesReceived
//         }
//     },
    
//     /**
//      * Function fromFirestore
//      * @property {Function} fromFirestore Function to convert data from database into PickleballPlayerStats object
//      * @param {FirebaseFirestore.QueryDocumentSnapshot} snapshot Data returned from database
//      * @param {FirebaseFirestore.SnapshotOptions} options Snapshot options
//      * @returns {PickleballPlayerStats}
//      */


//     fromFirestore: function (snapshot, options) {
//         const data = snapshot.data(options);
//         if (data == null) {
//             return data
//         }
//         return new PickleballPlayerStats (
// 			data.id,
// 			data.playerRating,
// 			data.gamesPlayed,
// 			data.wins,
// 			data.losses,
// 			data.winLossRatio,
// 			data.tournamentsPlayed,
// 			data.tournamentPlacements,
// 			data.mostRecentTournament,
// 			data.avgPointsScored,
// 			data.avgPointsAgainst,
// 			data.highestAchievedRating,
// 			data.currentRanking,
// 			data.favoriteShot,
// 			data.aceCount,
// 			data.faultCount,
// 			data.mostPlayedAgainst,
// 			data.picklesGiven,
// 			data.picklesReceived);
//     }
// }
// export default PickleballPlayerStats