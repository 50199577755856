export const adminHomeObj1 = {
  id            : 'update1',
  destination   : 'update2',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Launch',
  headline      : 'Getting Started',
  description   : 'Paddle Portal Beta launches September 19, 2023.',
  buttonLabel   : 'Next Update',
  imgStart      : false,
  img           : require('../../assets/images/launch.svg').default,
  alt           : 'Launch',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const adminHomeObj2 = {
  id            : 'update2',
  destination   : 'update3',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'First User',
  headline      : 'Mr Dink',
  description   : 'First Beta user added to Paddle Portal September 21, 2023',
  buttonLabel   : 'Next Update',
  imgStart      : true,
  img           : require('../../assets/images/firstUser.svg').default,
  alt           : 'First User',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const adminHomeObj3 = {
  id            : 'update3',
  destination   : 'update4',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Connections',
  headline      : 'Back End Connected',
  description   : 'Paddle Portal back end now connected to public domain September 26, 2023.',
  buttonLabel   : 'Next Update',
  imgStart      : false,
  img           : require('../../assets/images/connection.svg').default,
  alt           : 'Connection',
  dark          : true,
  primary       : true,
  darkText      : false
};
export const adminHomeObj4 = {
  id            : 'update4',
  destination   : 'update5',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Engagement',
  headline      : 'Community Interest Peaks',
  description   : 'Reviewed emails from interested participants, ramped up development, and started compiling a public beta user list on March 23, 2024.',
  buttonLabel   : 'Next Update',
  imgStart      : true,
  img           : require('../../assets/images/emailReview.svg').default, // Replace with your actual image file
  alt           : 'Email Review',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const adminHomeObj5 = {
  id            : 'update5',
  destination   : 'notify-me', // Set the destination if you have further updates
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Launch',
  headline      : 'Social Media and Updates Rollout',
  description   : 'Launched social media profiles and implemented small updates to the web page and iOS app on March 27, 2024.',
  buttonLabel   : 'Sign up for updates', // Set the button label if this leads to another update or action
  imgStart      : false,
  img           : require('../../assets/images/socialMediaLaunch.svg').default, // Replace with your actual image file
  alt           : 'Social Media Launch',
  dark          : true,
  primary       : true,
  darkText      : false
};