// portalSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firestore } from '../../firebase/utils';
import { portalConverter } from '../../model/Portal'; // Adjust this path if necessary

// Async thunk for fetching Portal data
export const fetchPortal = createAsyncThunk('portal/fetchPortal', async (portalId) => {
    const docRef = firestore.collection('portals').doc(portalId).withConverter(portalConverter);
    const snapshot = await docRef.get();
    // if (snapshot.exists) 
    if (snapshot.exists) {
        return snapshot.data(); // This will automatically convert to a Portal instance
    } else {
        throw new Error('Portal not found');
    }
});

// Slice
export const portalSlice = createSlice({
    name: 'portal',
    initialState: {
        portal: null,
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPortal.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPortal.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.portal = action.payload;
            })
            .addCase(fetchPortal.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default portalSlice.reducer;



// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// // import { firestore } from 'firebase'; // Adjust the path based on your project structure
// import { firestore } from '../../firebase/utils';
// import Portal from '../../model/Portal';

// // Async thunk for fetching Portal data
// export const fetchPortal = createAsyncThunk('portal/fetchPortal', async (portalId) => {
//     const snapshot = await firestore.collection('portals').doc(portalId).get();
//     if (snapshot.exists) {
//         return Portal.fromFirestore(snapshot); // Assuming fromFirestore converts Firestore data into a Portal object
//     }
//     throw new Error('Portal not found');
// });

// // Slice
// export const portalSlice = createSlice({
//     name: 'portal',
//     initialState: {
//         portal: null,
//         status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
//         error: null
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchPortal.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(fetchPortal.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.portal = action.payload;
//             })
//             .addCase(fetchPortal.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             });
//     }
// });

// export default portalSlice.reducer;
