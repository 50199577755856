// profileSlice.js

import {
    createSlice,
    createAsyncThunk }          from '@reduxjs/toolkit';
import { firestore }            from '../../firebase/utils';    // Adjust this path if necessary
import { profileConverter }     from '../../model/Profile';     // Ensure correct paths
import { matchResultConverter } from '../../model/MatchResult';
import store from '../createStore';

// Helper function to fetch match results
async function fetchMatchResults(profileId) {
    // Combined query for both teamA and teamB containing profileId, limited to the last 100 results
    const matchesQuery = firestore.collection('match-results')
        .where('playerIds', 'array-contains', profileId)
        .orderBy('startTime', 'desc') // Assuming there is a date field to order by
        .limit(500);
    const querySnapshot = await matchesQuery.get();
    return querySnapshot.docs.map(doc => matchResultConverter.fromFirestore(doc, {}));
}

// Async thunk for fetching a profile by ID
export const fetchProfile = createAsyncThunk('profile/fetchProfile', async (profileId, { rejectWithValue }) => {
    try {
        const docRef = firestore.collection('profiles').doc(profileId).withConverter(profileConverter);
        const snapshot = await docRef.get();
        
        if (!snapshot.exists) {
            throw new Error('Profile not found');
        }
        
        const profileData = snapshot.data(); // Automatically converted to a Profile instance
        const matchResults = await fetchMatchResults(profileId); // Fetch match results
        // Update profileData with fetched match results
        // profileData.statistics.matchResults = matchResults;
        store.currentProfile = profileData;
        store.currentProfile.statistics = profileData.statistics
        store.currentProfile.statistics.matchResults = matchResults

        return profileData;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

// Async thunk for updating a profile
export const updateProfile = createAsyncThunk('profile/updateProfile', async (profile) => {
    const docRef = firestore.collection('profiles').doc(profile.id).withConverter(profileConverter);
    await docRef.set(profile);
    return profile; // Return the updated profile
});

// Slice definition
export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        currentProfile: null,
        status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProfile.fulfilled, (state, action) => {
                
                state.status = 'succeeded';
                state.currentProfile = action.payload;
            })
            .addCase(fetchProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.currentProfile = action.payload;
            });
    }
});

export default profileSlice.reducer;


// import {
//     createSlice,
//     createAsyncThunk }      from '@reduxjs/toolkit';
// import { firestore }        from '../../firebase/utils'; // Adjust this path if necessary
// import { profileConverter } from '../../model/Profile';

// // Async thunk for fetching a profile by ID
// export const fetchProfile = createAsyncThunk('profile/fetchProfile', async (profileId) => {
//     const docRef    = firestore.collection('profiles').doc(profileId).withConverter(profileConverter);
//     const snapshot  = await docRef.get();
//     if (!snapshot.exists) {
//         throw new Error('Profile not found');
//     }
//     return snapshot.data(); // Automatically converted to a Profile instance
// });

// // Async thunk for updating a profile
// export const updateProfile = createAsyncThunk('profile/updateProfile', async (profile) => {
//     const docRef = firestore.collection('profiles').doc(profile.id).withConverter(profileConverter);
//     await docRef.set(profile);
//     return profile; // Return the updated profile
// });

// // Slice definition
// export const profileSlice = createSlice({
//     name: 'profile',
//     initialState: {
//         currentProfile: null,
//         status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
//         error: null
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchProfile.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(fetchProfile.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.currentProfile = action.payload;
//             })
//             .addCase(fetchProfile.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             })
//             .addCase(updateProfile.fulfilled, (state, action) => {
//                 state.currentProfile = action.payload;
//             });
//     }
// });

// export default profileSlice.reducer;
