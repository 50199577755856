//  Paddle.js
//
//  Created by Miguel Brown on 4/29/2024, 11:18:40 PM.
//  Copyright © 2024 Luckey Logic. All rights reserved.

import React from "react";

/**
* Class description: Paddle model represents a physical Pickleball paddle.
*/
class Paddle extends React.Component {
    
    /**
*@param {string}            [id]                - Unique identifier for each paddle. (Optional)
*@param {string}            [tagId]             - Unique identifier for the NFC tag. (Optional)
*@param {string}            [ownerId]           - Unique identifier for the owner. (Optional)
*@param {string}            name                - The name of the paddle
*@param {string}            brand               - The brand of the paddle
*@param {Number}            [weight]            - The weight of the paddle in ounces. (Optional)
*@param {Number}            [length]            - The length of the paddle in inches. (Optional)
*@param {Number}            [width]             - The width of the paddle in inches. (Optional)
*@param {Number}            [thickness]         - The thickness of the paddle in inches. (Optional)
*@param {string}            [composition]       - The composition of the paddle. (Optional)
*@param {string}            [hittingSurface]    - The composition of the hitting surface of the paddle. (Optional)
*@param {Number}            [gripSize]          - The grip size of the paddle in inches. (Optional)
*@param {Number}            [price]             - The price of the paddle in USD. (Optional)
*@param {Number}            [rating]            - The average rating of the paddle (e.g., 4.5 out of 5 stars) (Optional)
*@param {Number}            [reviewCount]       - The number of reviews for this paddle. (Optional)
*@param {Boolean}           [usaApproved]       - If USA Pickleball has approved this paddle. (Optional)
*@param {Date}              [listDate]          - The date the paddle was listed as USA Pickleball aprroved. (Optional)
*@param {string}            [imageUrl]          - The URL of the image of the paddle. (Optional)
*@param {Array<string>}     [gallery]           - The list of gallery image URLs. (Optional)
*@param {Array<Location>}   [locations]         - Location conforms to Sale Item protocol. Currently unused. (Optional)
    */
    
    constructor(id, tagId, ownerId, name, brand, weight, length, width, thickness, composition, hittingSurface, gripSize, price, rating, reviewCount, usaApproved, listDate, imageUrl, gallery, locations) {

        /**
	*@property { string }   id              - Unique identifier for each paddle.
	*@property { string }   tagId           - Unique identifier for the NFC tag.
	*@property { string }   ownerId         - Unique identifier for the owner.
	*@property { string }   name            - The name of the paddle
	*@property { string }   brand           - The brand of the paddle
	*@property { Number }   weight          - The weight of the paddle in ounces.
	*@property { Number }   length          - The length of the paddle in inches.
	*@property { Number }   width           - The width of the paddle in inches.
	*@property { Number }   thickness       - The thickness of the paddle in inches.
	*@property { string }   composition     - The composition of the paddle.
	*@property { string }   hittingSurface  - The composition of the hitting surface of the paddle.
	*@property { Number }   gripSize        - The grip size of the paddle in inches.
	*@property { Number }   price           - The price of the paddle in USD.
	*@property { Number }   rating          - The average rating of the paddle (e.g., 4.5 out of 5 stars)
	*@property { Number }   reviewCount     - The number of reviews for this paddle.
	*@property { Boolean }  usaApproved     - If USA Pickleball has approved this paddle.
	*@property { Date }     listDate        - The date the paddle was listed as USA Pickleball aprroved.
	*@property { string }   imageUrl        - The URL of the image of the paddle.
	*@property { string }   gallery         - The list of gallery image URLs.
	*@property { Location } locations       - Location conforms to Sale Item protocol. Currently unused.
        */

	super(id, tagId, ownerId, name, brand, weight, length, width, thickness, composition, hittingSurface, gripSize, price, rating, reviewCount, usaApproved, listDate, imageUrl, gallery, locations);

    this.id             = id; 
	this.tagId          = tagId; 
	this.ownerId        = ownerId; 
	this.name           = name; 
	this.brand          = brand; 
	this.weight         = weight; 
	this.length         = length; 
	this.width          = width; 
	this.thickness      = thickness; 
	this.composition    = composition; 
	this.hittingSurface = hittingSurface; 
	this.gripSize       = gripSize; 
	this.price          = price; 
	this.rating         = rating; 
	this.reviewCount    = reviewCount; 
	this.usaApproved    = usaApproved; 
	this.listDate       = listDate; 
	this.imageUrl       = imageUrl; 
	this.gallery        = gallery; 
	this.locations      = locations; 

    }
    /**
     * Function ToDict Converts the Paddle instance to a dictionary format suitable for database storage or transfer.
     * @function    ToDict
     * @returns     {Object} A dictionary representation of the Paddle instance.
     */

    ToDict() {
        return {
            "id"                : this.id,
			"tagId"             : this.tagId,
			"ownerId"           : this.ownerId,
			"name"              : this.name,
			"brand"             : this.brand,
			"weight"            : this.weight,
			"length"            : this.length,
			"width"             : this.width,
			"thickness"         : this.thickness,
			"composition"       : this.composition,
			"hittingSurface"    : this.hittingSurface,
			"gripSize"          : this.gripSize,
			"price"             : this.price,
			"rating"            : this.rating,
			"reviewCount"       : this.reviewCount,
			"usaApproved"       : this.usaApproved,
			"listDate"          : this.listDate,
			"imageUrl"          : this.imageUrl,
			"gallery"           : this.gallery,
			"locations"         : this.locations
        };
    }

    /**
    * Creates a Paddle instance from a dictionary format, typically retrieved from a database.
    * @funtion              FromDict
    * @property {Object}    data The data from database
    * @static
    * @returns {Paddle}     Returns a Paddle instance created from the provided data.
    */

    static FromDict(data) {
        if (data == null) {
            return data
        }
        return new Paddle(
            data.id,
			data.tagId,
			data.ownerId,
			data.name,
			data.brand,
			data.weight,
			data.length,
			data.width,
			data.thickness,
			data.composition,
			data.hittingSurface,
			data.gripSize,
			data.price,
			data.rating,
			data.reviewCount,
			data.usaApproved,
			data.listDate,
			data.imageUrl,
			data.gallery,
			data.locations);
    }

}

/**
* A Paddle
* @typedef  {FirebaseFirestore.FirestoreDataConverter} PaddleConverter
* @property {Function} toFirestore      - Function to send data to database
* @property {Function} fromFirestore    - Function to retrieve data from database
*/
        
/**
* @type PaddleConverter
*/

export const paddleConverter = {

    /**
     * Function toFirestore
     * @property    {Function}  toFirestore Function to send Paddle object to database
     * @param       {Paddle}    paddle      Paddle to be converted
     * @returns     {FirebaseFirestore.DocumentData}
     */

    toFirestore: function(paddle) {
        return {
            id              : paddle.id,
			tagId           : paddle.tagId,
			ownerId         : paddle.ownerId,
			name            : paddle.name,
			brand           : paddle.brand,
			weight          : paddle.weight,
			length          : paddle.length,
			width           : paddle.width,
			thickness       : paddle.thickness,
			composition     : paddle.composition,
			hittingSurface  : paddle.hittingSurface,
			gripSize        : paddle.gripSize,
			price           : paddle.price,
			rating          : paddle.rating,
			reviewCount     : paddle.reviewCount,
			usaApproved     : paddle.usaApproved,
			listDate        : paddle.listDate,
			imageUrl        : paddle.imageUrl,
			gallery         : paddle.gallery,
			locations       : paddle.locations
        }
    },
    
    /**
     * Function fromFirestore
     * @property    {Function}                                  fromFirestore   Function to convert data from database into Paddle object
     * @param       {FirebaseFirestore.QueryDocumentSnapshot}   snapshot        Data returned from database
     * @param       {FirebaseFirestore.SnapshotOptions}         options         Snapshot options
     * @returns     {Paddle}
     */


    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        if (data == null) {
            return data
        }
        return new Paddle (data.id,
			data.tagId,
			data.ownerId,
			data.name,
			data.brand,
			data.weight,
			data.length,
			data.width,
			data.thickness,
			data.composition,
			data.hittingSurface,
			data.gripSize,
			data.price,
			data.rating,
			data.reviewCount,
			data.usaApproved,
			data.listDate,
			data.imageUrl,
			data.gallery,
			data.locations);
    }
}
export default Paddle